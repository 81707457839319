import React from "react";
import styled from "styled-components";
// Assuming you've installed react-icons, we'll use placeholders for now
import { FaComment, FaUsers, FaLaptopCode, FaBrain } from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import { keyframes, css } from "styled-components";

const fadeIn = () => keyframes`
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  background-color: #ed9a3f; // Assuming this is the background color from the image.
  color: white;
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h2`
  font-size: 4.5rem;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #fff;
`;

const SubTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #1e5a8a;
  margin-top: -25px;

  @media (max-width: 900px) {
    margin-top: 0px;
  }

  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-wrap: wrap; // Allow items to wrap onto the next line if they run out of space.
  justify-content: space-around;
  //max-width: 960px; // Adjust to control the maximum width of the entire container.
  margin: 0 auto; // Center the container.
  padding: 0% 10% 5% 10%;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding: 0% 5% 5% 5%;
  }
`;

const Benefit = styled.div`
  display: flex;
  max-width: 45%; // Roughly half of the container, accounting for some margin.
  margin: 20px 2.5%; // Some margin to space the items out. Adjust as needed.
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 1200px) {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }

  opacity: 0;

  ${({ inView, duration }) =>
    inView &&
    css`
      animation: ${fadeIn(duration)} ${duration} forwards;
    `}
`;

const IconContainer = styled.div`
  background-color: white;
  padding: 25px;
  border-radius: 50%;
  width: max-content;
  height: max-content;
`;

const BenefitText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0px 0px 0px 40px;
  margin: 0px;

  @media (max-width: 600px) {
    text-align: center;
    padding: 0px 0px 0px 0px;
  }
`;

const BenefitTitle = styled.h3`
  font-size: 2rem;
  padding: 0px;
  margin: 0px;
`;

const BenefitDescription = styled.p`
  font-size: 1.5rem;
`;

const WaveSVG = styled.svg`
  width: 100%;
  margin-bottom: -15px;
  ${(props) =>
    props.reverse
      ? "transform: rotate(180deg); margin-top: -5%;"
      : "  margin-top: 100px;"}
`;

const Why = () => {
  const [ref1, inView1] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: false, threshold: 0.1 });

  return (
    <div style={{ paddingBottom: 100 }}>
      <WaveSVG viewBox="0 20 1440 150">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 130">
          <path
            fill="#ed9a3f"
            d="m.5,86.92S156,.5,499,.5s760,79,971,79,450.5-52.67,450.5-52.67v152.7H.5v-92.61Z"
          />
        </svg>
      </WaveSVG>
      <Container>
        <SubTitle>THE BENEFITS</SubTitle>
        <Title>Why GetLit?</Title>
        <BenefitContainer>
          <Benefit ref={ref1} inView={inView1} duration="1s">
            <IconContainer>
              <FaLaptopCode size={100} color="#1e5a8a" />
            </IconContainer>
            <BenefitText>
              <BenefitTitle>Modern Learning</BenefitTitle>
              <BenefitDescription>
                Embrace the power of trending media in education. With GetLit,
                traditional teaching meets the digital age, ensuring lessons
                resonate with today's students.
              </BenefitDescription>
            </BenefitText>
          </Benefit>
          <Benefit ref={ref2} inView={inView2} duration="1.5s">
            <IconContainer>
              <FaBrain size={100} color="#1e5a8a" />
            </IconContainer>
            <BenefitText>
              <BenefitTitle>Interactive Boards</BenefitTitle>
              <BenefitDescription>
                Sections in every board aren't just informative—they're
                interactive. Students can comment, vote, and engage, turning
                passive learning into active discovery
              </BenefitDescription>
            </BenefitText>
          </Benefit>
          <Benefit ref={ref3} inView={inView3} duration="2s">
            <IconContainer>
              <FaUsers size={100} color="#1e5a8a" />
            </IconContainer>
            <BenefitText>
              <BenefitTitle> Tailored Content</BenefitTitle>
              <BenefitDescription>
                Our intelligent curation selects media that aligns perfectly
                with your board content. Whether it's literature or news, GetLit
                finds the best match.
              </BenefitDescription>
            </BenefitText>
          </Benefit>
          <Benefit ref={ref4} inView={inView4} duration="2.5s">
            <IconContainer>
              <FaComment size={100} color="#1e5a8a" />
            </IconContainer>
            <BenefitText>
              <BenefitTitle>Community Engagement</BenefitTitle>
              <BenefitDescription>
                Boost classroom camaraderie as students share, like, and discuss
                the media-rich sections. Learning becomes a shared journey,
                enhancing comprehension and retention.
              </BenefitDescription>
            </BenefitText>
          </Benefit>
        </BenefitContainer>
      </Container>
      <WaveSVG reverse={true} viewBox="0 20 1440 150">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 130">
          <path
            fill="#ed9a3f"
            d="m.5,86.92S156,.5,499,.5s760,79,971,79,450.5-52.67,450.5-52.67v152.7H.5v-92.61Z"
          />
        </svg>
      </WaveSVG>
    </div>
  );
};

export default Why;
