import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight, FaCircle } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
   90% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Container = styled.div`
  background-color: #ed9a3f;
  color: white;
  text-align: center;
  margin-top: -3%;
`;

const TestimonialsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin: 0% 15%;
  padding-bottom: 100px;

  @media (max-width: 1400px) {
    margin: 0% 20px;
  }
`;

const Testimonial = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 800px;
  margin: 20px;
  text-align: center;

  animation: ${(props) => (props.fadeIn ? fadeInUp : fadeOut)} 1.25s;
`;

const Arrow = styled.div`
  cursor: pointer;
  font-size: 2rem;
  color: #ed9a3f;
  margin: 10px;
  padding: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  jusitfy-content: center;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  top: 100px;
  font-size: 40px;
  font-weight: bold;
  transform: translateY(-50%);
  ${(props) => (props.left ? "left: 20px;" : "right: 20px;")}

  @media (max-width: 900px) {
    top: 200px;
    padding: 10px;
    display: none;
  }
`;

const WaveSVG = styled.svg`
  width: 100%;
  padding-top: 50px;
  //margin-bottom: -0.5%;
  ${(props) =>
    props.reverse ? "transform: rotate(180deg); margin-top: -5%;" : ""}
`;

const FadedImage = styled.div`
  width: 100%; // Adjust as per your requirements
  height: 400px; // Adjust as per your requirements
  background: linear-gradient(
      to top,
      rgba(237, 154, 63, 1) 15%,
      rgba(237, 154, 63, 0.33) 50%,
      rgba(237, 154, 63, 1) 100%
    ),
    url("images/teenagers-classroom3.png") center/cover no-repeat;
`;

const CircleNav = styled.div`
  display: flex;
  gap: 5px; /* Adjusted the gap */

  @media (min-width: 900px) {
    display: none;
  }
`;

const CircleButton = styled.div`
  background: none;
  border: none;
  color: ${(props) => (props.active ? "#fff" : "rgba(255,255,255,0.5)")};
  font-size: 15px; /* Adjusted font-size for smaller circles */
  transition: color 0.3s; /* Smooth transition for color */
`;

const TestimonialContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
  min-height: 350px;
  animation: ${(props) => (props.fadeIn ? fadeInUp : fadeOut)} 1.25s;
`;

const Name = styled.h4`
  font-size: 1.5rem;
  margin-top: 10px;
  color: #1e5a8a;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 1.5rem;
  max-width: 800px;
  text-align: center;
  margin-top: -30px;
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4.5rem;
  text-align: center;
  top: 0;
  font-weight: bold;
  margin-top: -350px;
  padding-bottom: 249px;
  gap: 20px;

  height: 100px;
  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

const Button = styled.button`
  padding: 20px 50px;
  background-color: #fff;
  border: none;
  width: fit-content;
  border-radius: 15px 15px 15px 0px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ed9a3f;

  transition: all 0.3s;

  &:hover {
    background-color: #ed9a3f;
    color: #fff;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); // Slightly intensifying the shadow
  }
`;

const InfoScroller = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  // Updated Testimonials with name and description
  const testimonialsData = [
    {
      text: "Using GetLit has changed how I view schoolwork! Instead of just reading text, I'm watching videos and engaging with content that feels relevant. I never thought I'd see a TikTok video in a lesson – and actually learn from it!",
      name: "Hannah",
      description: "Student, 11th Grade",
    },
    {
      text: "GetLit has transformed my classroom into a dynamic space of engagement. The platform allows me to integrate modern media seamlessly, and the response from my students has been incredible. They're not just learning; they're discussing, debating, and truly engaging with the material.",
      name: "Mr. Rodriguez",
      description: "High School Literature Teacher",
    },
    {
      text: "We've always sought tools that cater to the evolving needs of our students. GetLit does just that. It bridges the traditional with the contemporary, and the feedback from both teachers and students has been overwhelmingly positive. Plus, it aligns well with our school's commitment to innovative education.",
      name: "Linda",
      description: "School Administrator",
    },
    {
      text: "I wish I had GetLit when I was in high school! I've had a chance to use it in my college prep course, and it's made the texts we study so much more relatable. The platform just gets how students today want to learn.",
      name: "Jake",
      description: "College Freshman",
    },
    {
      text: "I've seen many educational tools, but GetLit stands out. It not only incorporates media but also encourages deep thinking and discussion. It's been a pleasure recommending relevant media content for our teachers to use in their boards.",
      name: "Mrs. Thompson",
      description: "School Librarian",
    },
  ];

  const handleNavigation = (direction) => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentTestimonial((prev) =>
        direction === "next"
          ? (prev + 1) % testimonialsData.length
          : (prev - 1 + testimonialsData.length) % testimonialsData.length
      );
      setFadeIn(true);
    }, 1000);
  };

  return (
    <>
      <WaveSVG viewBox="0 20 1440 150">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 130">
          <path
            fill="#ed9a3f"
            d="m.5,86.92S156,.5,499,.5s760,79,971,79,450.5-52.67,450.5-52.67v152.7H.5v-92.61Z"
          />
        </svg>
      </WaveSVG>
      <Container>
        <TestimonialsContainer>
          <TestimonialContainer fadeIn={fadeIn}>
            <Testimonial>
              “{testimonialsData[currentTestimonial].text}”
            </Testimonial>
            <Name>{testimonialsData[currentTestimonial].name}</Name>
            <Description>
              {testimonialsData[currentTestimonial].description}
            </Description>
          </TestimonialContainer>
          <CircleNav>
            {testimonialsData.map((_, index) => (
              <CircleButton
                active={currentTestimonial === index}
                key={index}
                onClick={() => {
                  setFadeIn(false);
                  setTimeout(() => {
                    setCurrentTestimonial(index);
                    setFadeIn(true);
                  }, 500); // Added delay for smoother transitions
                }}
              >
                <FaCircle />
              </CircleButton>
            ))}
          </CircleNav>
          {/* Arrows for larger screens only */}
          <Arrow left onClick={() => handleNavigation("prev")}>
            <FaArrowLeft />
          </Arrow>
          <Arrow onClick={() => handleNavigation("next")}>
            <FaArrowRight />
          </Arrow>
        </TestimonialsContainer>
        <FadedImage />
        <HeroText>
          Considering getting started today?
          <Button>
            <a
              style={{ textDecorationLine: "none", color: "black" }}
              href="https://getlitnow.org"
            >
              Try GetLit Now
            </a>
          </Button>
        </HeroText>
      </Container>
      <WaveSVG reverse viewBox="0 20 1440 150">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 130">
          <path
            fill="#ed9a3f"
            d="m.5,86.92S156,.5,499,.5s760,79,971,79,450.5-52.67,450.5-52.67v152.7H.5v-92.61Z"
          />
        </svg>
      </WaveSVG>
    </>
  );
};

export default InfoScroller;
