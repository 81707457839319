import React from "react";
import styled, { keyframes, css } from "styled-components";
import { FaApple, FaGlobe } from "react-icons/fa";
import { useInView } from "react-intersection-observer";

// Animation
const fadeIn = () => keyframes`
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Container holding the entire component
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0% 20%;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// Styled heading
const Heading = styled.h1`
  font-size: 4.5rem;
  color: #030852;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Styled buttons
const Button = styled.a`
  display: flex;
  margin: 10px;
  padding: 5px 20px 5px 10px;
  border-radius: 10px;
  border: #ed9a3f solid 2px;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.5rem;
  color: #ed9a3f;
  align-items: center;
  width: auto;

  transition: all 0.3s;

  &:hover {
    border: #f5222d solid 2px;
    color: #f5222d;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); // Slightly intensifying the shadow
  }

  @media (max-width: 1400px) {
    width: 250px;
    justify-content: center;
  }

  opacity: 0;

  ${({ inView, duration }) =>
    inView &&
    css`
      animation: ${fadeIn(duration)} ${duration} forwards; // Use duration instead of fixed 0.5s
    `}
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Download = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <Container>
      <Heading>Teach and Learn with Trending Media!</Heading>
      <ButtonGroup ref={ref}>
        <Button href="https://getlitnow.org/" inView={inView} duration="1s">
          <FaGlobe size={50} style={{ marginRight: "10px" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 25 }}>Web Version</span>
          </div>
        </Button>
        <Button href="#" inView={inView} duration="1.5s">
          <FaApple size={50} style={{ marginRight: "10px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <span style={{ fontSize: 16, marginBottom: -8 }}>
              Download on the
            </span>
            <span style={{ fontSize: 30 }}>App Store</span>
          </div>
        </Button>
        <Button
          href="https://play.google.com/store/apps/details?id=com.goyellc.getlit&hl=en_US"
          inView={inView}
          duration="2s"
        >
          <img
            width={90}
            height={90}
            style={{ margin: -15 }}
            src="/logos/google-play.png"
            alt="Google play logo"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <span style={{ fontSize: 16, marginBottom: -8 }}>GET IT ON</span>
            <span style={{ fontSize: 30 }}>Google Play</span>
          </div>
        </Button>{" "}
      </ButtonGroup>
    </Container>
  );
};

export default Download;
