import React from "react";
import { DeviceFrameset } from "react-device-frameset";
import styled, { keyframes, css } from "styled-components";
import { useInView } from "react-intersection-observer";

import "react-device-frameset/styles/marvel-devices.min.css";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0% 10%;
  padding-bottom: 20px;
  gap: 20px;

  flex-direction: ${(props) => (!props.right ? "row-reverse" : "row")};

  @media (max-width: 1200px) {
    flex-direction: column;
    margin-top: 0px;
    padding: 5% 10%;
    align-items: center;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  padding: 75px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    border-radius: 20px;
    padding: 50px 0px 0px 0px;
  }

  @media (max-width: 500px) {
    background-color: #fff;
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  color: #030852;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 3rem;
    margin-top: 0px;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  color: #030852;
  margin: 0px 0px 20px 0px;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 1rem;
    padding-bottom: 20px;
  }
`;

const RightContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.right ? "flex-start" : "flex-end")};

  @media (max-width: 1200px) {
    text-align: center;
    align-items: center;
  }
`;

const Button = styled.button`
  padding: 20px 20px;
  background-color: #ed9a3f;
  color: ed9a3f;
  border: none;
  width: 75%;
  border-radius: 15px 15px 15px 0px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;

  transition: all 0.3s;

  &:hover {
    background-color: #f5222d;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); // Slightly intensifying the shadow
  }
`;

// Define the fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedDiv = styled.div`
  max-width: 400px;
  align-self: ${(props) => (props.right ? "flex-start" : "flex-end")};

  opacity: 0;

  ${({ inView }) =>
    inView &&
    css`
      animation: ${fadeIn} 1s forwards;
    `}
`;

const Info = ({
  title = "Enter title...",
  subtitle = "Enter much longer subtitle...",
  buttonText,
  right = true,
  screenShotSrc = "/screenshots/create_intro.jpg",
  buttonLink,
  ...props
}) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <Container right={right} ref={ref}>
      <LeftContent right={right}>
        <DeviceFrameset device={"Nexus 5"} height={"auto"} landscape={false}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={screenShotSrc}
            alt="A screenshot of the GetLit app"
          />
        </DeviceFrameset>
      </LeftContent>
      <RightContent>
        <AnimatedDiv inView={inView} right={right}>
          <Title>{title}</Title>
          <Text>{props.children}</Text>
          {buttonText && (
            <Button>
              <a
                style={{ textDecorationLine: "none", color: "white" }}
                href={buttonLink}
              >
                {buttonText}
              </a>
            </Button>
          )}
        </AnimatedDiv>
      </RightContent>
    </Container>
  );
};

export default Info;
