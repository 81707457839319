import React from "react";
import styled from "styled-components";

const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, #9783ec, #32009a);
  padding: 10% 0% 0% 10%;
  flex-direction: row;
  z-index: 0; // ensure content stays on top
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 15% 0% 0% 0%;
    background: linear-gradient(to bottom, #9783ec, #32009a);
  }
`;

const ImageContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  width: 80%; // making it 60% of the HeroContainer's width
  height: auto;
  z-index: -1; // this ensures it's behind the content

  margin-top: -200px;

  img {
    width: 100%;
    height: auto;
    //transform: translateY(100px);
    object-fit: contain; // This will ensure the image covers the entire container without distorting its aspect ratio
    max-height: 800px;
  }

  @media (max-width: 1000px) {
    margin-top: -50px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 0;
`;

const ContentContainer = styled.div`
  max-width: 50%;
  z-index: 0;
  padding-bottom: 100px;

  @media (max-width: 800px) {
    max-width: 100%;
    padding: 20px;
    padding-top: 100px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #030852;

  @media (max-width: 1300px) {
    margin-top: 100px;
  }

  @media (max-width: 500px) {
    margin-top: 150px;
  }
`;

const WaveSVG = styled.svg`
  width: 100%;
  z-index: 10;
  bottom: 0%;
  margin-top: -5%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px; // space between chat bubbles

  margin-bottom: 100px;

  @media (max-width: 800px) {
    flex-direction: column; // Stack buttons vertically on mobile
    align-items: center; // Center-align the buttons
    gap: 10px; // Reduce the gap for mobile
    margin-bottom: 50px;
  }
`;

const ChatButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 20px 36px;
  background-color: ${(props) => (props.primary ? "#ed9a3f" : "#fff")};
  color: ${(props) => (props.primary ? "#fff" : "#333")};
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); // Shadow for a lifted effect
  font-size: 1.5rem;
  ${(props) => (props.primary ? "align-self:flex-end;" : "")}

  z-index: 100;

  ${(props) => (props.primary ? "transform: translateY(45px);" : "")}

  &:after {
    content: "";
    position: absolute;
    ${(props) => (props.left ? "left: 3px" : "right: 3px")};
    bottom: 3px;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    border-bottom-left-radius: 0;
    background-color: inherit;
    transform: rotate(${(props) => (props.left ? "-30deg" : "-60deg")});
  }

  &:hover {
    background-color: ${(props) =>
      props.primary
        ? "#f5222d"
        : "#f0f0f0"}; // Darkening the primary color and lightening the non-primary color
    color: ${(props) =>
      props.primary ? "#fff" : "#333"}; // Adjusting text color accordingly
    ${(props) =>
      props.primary
        ? "transform: translateY(25px);"
        : "transform: translateY(-3px);"}

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); // Slightly intensifying the shadow
  }
`;

const Hero = () => {
  return (
    <Container>
      <HeroContainer>
        <ContentContainer>
          <Title>
            <span style={{ fontWeight: "400" }}>Literary Exploration,</span>
            <span style={{ fontWeight: "bold" }}>
              <br></br>Reframed for Modern Audiences
            </span>
          </Title>
          <ButtonContainer>
            <ChatButton left>
              I'm just not understanding the text im reading...
            </ChatButton>
            <ChatButton primary={true}>
              <a
                style={{ textDecorationLine: "none", color: "white" }}
                href="https://getlitnow.org/"
              >
                Download GetLit Now
              </a>
            </ChatButton>
          </ButtonContainer>
        </ContentContainer>
        <ImageContainer>
          <img
            src="/images/teenagers-using-phones-white.png"
            alt="A group of teenagers using their phones"
          />
        </ImageContainer>
      </HeroContainer>
      <WaveSVG viewBox="0 20 1440 150">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 130">
          <path
            fill="#fff"
            d="m.5,86.92S156,.5,499,.5s760,79,971,79,450.5-52.67,450.5-52.67v152.7H.5v-92.61Z"
          />
        </svg>
      </WaveSVG>
    </Container>
  );
};

export default Hero;
