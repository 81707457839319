// ContactForm.js

import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

// Styled components for the form
const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
`;

const StyledButton = styled.button`
  padding: 10px 15px;
  background-color: #1e5a8a;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ContactForm = ({ buttonText = "Submit" }) => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send email via EmailJS
    emailjs
      .send(
        "service_qif4d0o",
        "template_sh9qmii",
        formData,
        "aR9XBz1Klidyr3vMM"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({ name: "", email: "", message: "" }); // Reset form after successful submission
        },
        (error) => {
          console.error("FAILED...", error);
        }
      );
  };

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          type="text"
          name="from_name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
        />
        <StyledInput
          type="email"
          name="from_email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
        />
        <StyledTextArea
          name="message"
          placeholder="Your Message"
          rows="5"
          value={formData.message}
          onChange={handleChange}
        />
        <StyledButton type="submit">{buttonText}</StyledButton>
      </StyledForm>
    </FormContainer>
  );
};

export default ContactForm;
