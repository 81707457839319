import Title from "../components/General/Title";
import DownloadWaves from "../components/Home/DownloadWaves";
import Info from "../components/Home/Info";
import Subhero from "../components/Home/SubHero";

export default function OurModel() {
  return (
    <>
      <Title title="What is Our Model?" />
      <Subhero title={"What is GetLit?"} showButton={false}>
        GetLit is an innovative AI-driven platform designed for educators,
        offering a transformative approach to teaching literature. By infusing
        classic and contemporary texts with culturally relevant media, it
        bridges the gap between traditional literature and the interests of
        today's students. With GetLit, teachers can curate, customize, and
        deliver content that resonates, ensuring a deeper engagement and a more
        meaningful learning experience.
      </Subhero>
      <div style={{ height: 100 }} />
      <Info
        title="AI-Driven Curation"
        screenShotSrc="/screenshots/create_review1.jpg"
      >
        GetLit is not just another educational tool; it's powered by
        state-of-the-art AI technology. This intelligent system actively scans
        and selects culturally relevant media that best aligns with different
        pieces of literature. <br />
        This ensures that the content presented is not only appropriate but also
        engaging and relevant to today's dynamic cultural landscape.
      </Info>
      <Info
        right={false}
        title="Enhanced Student Engagement"
        screenShotSrc="/screenshots/viewing_2.jpg"
      >
        The modern student lives in a digital world, consuming a vast array of
        media daily. GetLit bridges the gap between traditional literature and
        the media-rich environment students are accustomed to.
        <br />
        By making connections between texts and contemporary media, students
        find a renewed interest and deeper understanding of their studies.
      </Info>
      <Info
        title="Customizable Content"
        screenShotSrc="/screenshots/board_create.gif"
      >
        Every classroom is unique, and so are the needs of every teacher.
        <br />
        Recognizing this, GetLit allows educators to tailor content to better
        align with their specific curriculum and the diverse needs of their
        students. This flexibility ensures that lessons remain fresh, relevant,
        and tailored to individual classroom dynamics.
      </Info>
      <Info
        title="Public Boards"
        screenShotSrc="/screenshots/vault_1.jpg"
        right={false}
      >
        Collaboration is at the heart of great teaching. GetLit's public boards
        serve as a communal space where educators can explore, share, and get
        inspired by content curated by their peers from around the world. It's a
        hub of collective knowledge and creativity.
      </Info>
      <Info title="Reboarding" screenShotSrc="/screenshots/reboarding.gif">
        Time is precious for educators. With the reboarding feature, teachers
        can effortlessly adapt and utilize boards created by others. <br />
        This not only saves valuable preparation time but also allows educators
        to tap into the collective expertise and creativity of the wider GetLit
        community.
      </Info>
      <Info
        title="User-Friendly Interface"
        screenShotSrc="/screenshots/vault_search.jpg"
        right={false}
      >
        At its core, GetLit is designed with the educator in mind. Its
        user-friendly interface ensures that teachers and students can navigate,
        manage content, and integrate media seamlessly, without the need for
        extensive tech know-how. <br />
        It's all about making the users experience as smooth and effective as
        possible.
      </Info>
      <Info
        title="Diverse Media Integration"
        buttonText={"Try GetLit Now"}
        screenShotSrc="/screenshots/personalize.gif"
        buttonLink={"www.getlitnow.org"}
      >
        Literature is multidimensional, and so is the media landscape. GetLit
        recognizes this and integrates a wide variety of media types — from
        videos, articles, podcasts to music. <br />
        This rich media environment ensures that every piece of literature is
        explored in its full depth, providing students with a comprehensive and
        enriched learning experience.
      </Info>
      <DownloadWaves />
    </>
  );
}
