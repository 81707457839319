// FAQComponent.js

import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0px auto 50px auto;

  @media (max-width: 900px) {
    max-width: 90%;
  }
`;

const Question = styled.div`
  background: #f7f7f7;
  padding: 15px 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;

  @media (max-width: 1400px) {
    max-width: 100%;
  }
`;

const Answer = styled.div`
  background: #fff;
  padding: 10px 20px 20px;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Question onClick={() => setIsOpen(!isOpen)}>{question}</Question>
      <Answer isOpen={isOpen}>{answer}</Answer>
    </div>
  );
};

const FAQComponent = () => {
  const faqData = [
    {
      question: "What is GetLit?",
      answer:
        "GetLit is an AI-powered tool designed to assist educators in infusing their literature and texts with culturally relevant media. This ensures that students can relate better to the content, making the learning experience more engaging and relevant.",
    },
    {
      question: "How does GetLit work?",
      answer:
        'GetLit analyzes the content provided by educators and suggests related media from various sources. Teachers can also explore public boards curated by other educators and "reboard" suitable content to present to their students.',
    },
    {
      question: "Is GetLit suitable for all grade levels?",
      answer:
        "Yes! GetLit is versatile and can be tailored to suit the needs of students from elementary to high school levels.",
    },
    {
      question: "How much does GetLit cost?",
      answer:
        "The free tier allocates teachers 100 free modality generation tokens a month. We also offer school-wide packages that allow institutions to purchase bulk accounts for their educators and students.",
    },
    {
      question: "How is my students' privacy protected?",
      answer:
        "Student privacy is our top priority. We ensure that all data is encrypted and never shared with third-party vendors. For a detailed breakdown, please refer to our privacy policy.",
    },
    {
      question:
        "Can I share the content I create on GetLit with other teachers?",
      answer:
        "Absolutely! GetLit encourages collaborative learning. You can share your boards with other educators, or you can browse and use content that other educators have made public.",
    },
    {
      question:
        "What if I need specific culturally relevant content that GetLit doesn't provide?",
      answer:
        "GetLit's AI is constantly learning and updating its database. However, if there's specific content you need, you can always reach out to us, and we'll do our best to assist.",
    },
    {
      question: 'How does the "reboard" feature work?',
      answer:
        'The "reboard" feature allows teachers to take content from public boards created by other educators and tailor it for their own students. It\'s a way to share and adapt valuable resources within the GetLit community.',
    },
    {
      question: "Do I need any specific tech skills to use GetLit?",
      answer:
        "No advanced tech skills are required! GetLit is designed to be user-friendly, with intuitive interfaces that make it easy for educators to integrate into their curriculum.",
    },
    {
      question: "Who do I contact if I have issues or feedback?",
      answer:
        "We value your feedback and are here to help. If you have any issues or suggestions, please visit our contact page and drop us a message.",
    },
  ];

  return (
    <Container>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </Container>
  );
};

export default FAQComponent;
