import FAQComponent from "../components/FAQs/FAQComponent";
import SectionTitle from "../components/General/SectionTitle";
import Title from "../components/General/Title";
import Download from "../components/Home/Download";
import InfoScroller from "../components/Home/InfoScroller";

export default function FAQs() {
  return (
    <>
      <Title title="Frequently Asked Questions" />
      <SectionTitle
        title="Unraveling the GetLit Experience"
        subtitle="Your questions answered, as we dive deep into the features and possibilities of GetLit."
      />
      <FAQComponent />
      <InfoScroller />
      <Download />
    </>
  );
}
