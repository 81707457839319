import React from "react";

const YouTubeEmbed = ({ videoId, width = 560, height = 315 }) => (
  <iframe
    style={{ borderRadius: 15, maxWidth: width, width: "100%" }}
    width={width}
    height={height}
    src={`https://www.youtube.com/embed/${videoId}`}
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    title={`YouTube Video ${videoId}`}
  ></iframe>
);

export default YouTubeEmbed;
