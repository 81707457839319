import Download from "../components/Home/Download";
import Hero from "../components/Home/Hero";
import Info from "../components/Home/Info";
import InfoScroller from "../components/Home/InfoScroller";
import SubHero from "../components/Home/SubHero";
import Why from "../components/Home/Why";

export default function Home() {
  return (
    <>
      <Hero />
      <SubHero title={"Experience Learning Through Media"}>
        Dive into a transformative educational platform where teachers can
        seamlessly integrate trending media like TikToks and YouTube videos into
        their lesson boards. Based on the unique content of each board, whether
        it's literature or current news, GetLit curates engaging media,
        fostering a dynamic learning environment. Students can then interact,
        comment, like, and vote on these media-rich sections, making learning
        more interactive and contemporary.
      </SubHero>
      <Download />
      <Why />
      <Info
        title="Making Education Relevant Again"
        screenShotSrc="/screenshots/create_review1.jpg"
      >
        Traditional learning often feels disconnected from students' personal
        interests and the modern world.
        <br />
        <br />
        With GetLit, lessons are infused with trending media, bridging the gap
        between academia and real-world relevance. As students engage with
        content they relate to, they're more likely to retain and cherish the
        knowledge.
      </Info>
      <Info
        right={false}
        title="Engage, Discuss, Discover"
        screenShotSrc="/screenshots/viewing_2.jpg"
        //buttonText={"Our Model"}
        //buttonLink="/our-model"
      >
        With every piece of text added to a board in GetLit, a world of related
        media opens up.
        <br />
        <br />
        Students aren't just passive readers; they engage, comment, and delve
        deeper into topics, making each board a living, evolving entity of
        learning and discovery
      </Info>
      <Info
        title="Every Student's Journey is Unique"
        buttonText={"Try GetLit Now"}
        buttonLink={"https://getlitnow.org/"}
        screenShotSrc="/screenshots/student-data.jpg"
      >
        Every board in GetLit is a reflection of the diverse learning pathways
        educators create. With our platform, the progress isn't just about
        grades. It's about understanding, engagement, and interaction.
        <br />
        <br />
        Teachers can monitor individual student engagement through our Analytics
        feature, understanding more than just performance – but passion.
      </Info>
      <InfoScroller />
    </>
  );
}
