import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { AiFillCloseCircle } from "react-icons/ai";

const Nav = styled.nav`
  z-index: 999;
  display: flex;
  width: 100%;
  top: 0;
  position: fixed; // Change this line
  align-items: center;
  background-color: ${(props) =>
    props.isScrolled ? "rgba(255, 255, 255, 0.75);" : "rgba(0, 0, 0, 0.25);"};
  backdrop-filter: blur(5px);
  justify-content: space-between;

  transition: all 0.3s;

  height: ${(props) => (props.isScrolled ? "75px" : "150px")};
`;

const Logo = styled.div`
  padding: 20px 5%;

  a {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: #ed9a3f;
    text-decoration: none;
    font-size: ${(props) => (props.isScrolled ? "1.5rem" : "3.5rem")};
    font-weight: ${(props) => (props.isScrolled ? "bold" : "bolder")};
  }

  transition: all 0.3s;

  justify-content: center;

  img {
    width: ${(props) => (props.isScrolled ? "40px" : "50px")};
    height: ${(props) => (props.isScrolled ? "40px" : "50px")};
  }
`;

const Menu = styled.ul`
  display: flex;
  list-style: none;
  padding: 20px;

  @media (max-width: 780px) {
    position: absolute;
    top: ${(props) => (!props.isScrolled ? "-20px" : "-20px")};
    right: 0;
    width: 50vw;
    flex-direction: column;
    background-color: #ed9a3f;
    border-radius: 5px 0 0 5px;
    transform: ${(props) =>
      props.openMenu ? "translateX(0)" : "translateX(100%)"};
    gap: 20px;
    backdrop-filter: blur(5px);
    transition: all 0.3s;

    height: 100vh;

    // Add some shadow for depth
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);

    // Rounded corners
    border-radius: 0 0 0 15px;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    padding: 10px;
    align-items: center;
  }
`;

const MenuItem = styled.li`
  margin-left: 3rem;
  color: white;

  // Targeting the Link component
  a {
    color: ${(props) =>
      !props.active ? (!props.isScrolled ? "#fff" : "#030852") : "#ed9a3f"};
    text-decoration: none;

    padding-bottom: ${(props) =>
      props.active ? (!props.isScrolled ? "51px" : "17px") : "0px"};

    padding-left: ${(props) => (props.active ? "20px" : "none")};
    padding-right: ${(props) => (props.active ? "20px" : "none")};
    margin-left: ${(props) => (props.active ? "-20px" : "0px")};
    margin-right: ${(props) => (props.active ? "-20px" : "0px")};

    border-bottom: ${(props) => (props.active ? "solid 4px #ed9a3f" : "none")};

    font-weight: bold;

    &:hover {
      // You can also style the hover state
      color: #ed9a3f; // For example, change color on hover
    }

    font-weight: ${(props) => (props.isScrolled ? "500" : "600")};
    font-size: ${(props) => (props.isScrolled ? "1.5rem" : "1.75rem")};

    @media (max-width: 780px) {
      color: white;

      &:hover {
        // You can also style the hover state
        color: #fa541c; // For example, change color on hover
        transform: translateY(-10px);
      }

      border-radius: 0px;
      border-bottom: none;
      border-left: ${(props) => (props.active ? "solid 4px #fff" : "none")};
      padding: ${(props) => (props.active ? "0px 0px 0px 10px" : "0px")};
    }
  }

  &:hover {
    // You can also style the hover state
    transform: translateY(-1px);
  }
`;

const CloseIcon = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: #fff;

    transition: all 0.3s;

    &:hover {
      color: #fa541c;
      transform: translateY(-1px);
    }
  }
`;

const MenuBlur = styled.div`
  backdrop-filter: ${(props) => (props.openMenu ? "blur(5px)" : "blur(0px)")};

  transition: all 0.3s;

  height: 100vh;
  width: ${(props) => (props.openMenu ? "50vw" : "0vw")};

  @media (max-width: 780px) {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY > 50; // you can adjust this value
      setIsScrolled(scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const closeMenu = (e) => {
      if (openMenu && !document.querySelector("ul").contains(e.target)) {
        setOpenMenu(false);
      }
    };

    window.addEventListener("mousedown", closeMenu);
    return () => {
      window.removeEventListener("mousedown", closeMenu);
    };
  }, [openMenu]);

  return (
    <Nav isScrolled={isScrolled}>
      <Logo isScrolled={isScrolled}>
        <Link to="/" onClick={() => setOpenMenu(false)}>
          <img src="/images/logo.png" alt="Logo for get lit" />
          <span>GetLit</span>
        </Link>
      </Logo>
      <MenuIcon onClick={() => setOpenMenu(!openMenu)}>
        <div
          style={{
            width: "25px",
            height: "4px",
            borderRadius: 5,
            backgroundColor: "#ed9a3f",
          }}
        ></div>
        <div
          style={{
            width: "25px",
            height: "4px",
            borderRadius: 5,
            backgroundColor: "#ed9a3f",
          }}
        ></div>
        <div
          style={{
            width: "25px",
            height: "4px",
            borderRadius: 5,
            backgroundColor: "#ed9a3f",
          }}
        ></div>
      </MenuIcon>
      <MenuBlur openMenu={openMenu} />
      <Menu openMenu={openMenu} isScrolled={isScrolled}>
        <CloseIcon onClick={() => setOpenMenu(!openMenu)}>
          <AiFillCloseCircle size={30} />
        </CloseIcon>
        {/*<MenuItem
          isScrolled={isScrolled}
          active={location.pathname === "/our-model"}
        >
          <NavLink to="/our-model" onClick={() => setOpenMenu(false)}>
            Our Model
          </NavLink>
        </MenuItem>*/}
        <MenuItem
          isScrolled={isScrolled}
          active={location.pathname === "/pricing"}
        >
          <NavLink to="/pricing" onClick={() => setOpenMenu(false)}>
            Pricing
          </NavLink>
        </MenuItem>
        <MenuItem
          isScrolled={isScrolled}
          active={location.pathname === "/faqs"}
        >
          <NavLink to="/faqs" onClick={() => setOpenMenu(false)}>
            FAQs
          </NavLink>
        </MenuItem>
        <MenuItem
          isScrolled={isScrolled}
          active={location.pathname === "/contact"}
        >
          <NavLink to="/contact" onClick={() => setOpenMenu(false)}>
            Contact
          </NavLink>
        </MenuItem>
      </Menu>
    </Nav>
  );
};

export default Navbar;
