import SectionTitle from "../components/General/SectionTitle";
import Title from "../components/General/Title";
import ContactForm from "../components/Home/Contact";
import Download from "../components/Home/Download";
import InfoScroller from "../components/Home/InfoScroller";

export default function Pricing() {
  return (
    <>
      <Title title="Pricing" />
      <SectionTitle
        title="Unlock the Power of Culturally Relevant Media for Your Students!"
        subtitle="Empower educators with GetLit, blending classic literature and contemporary media to foster deeper connections and engagement. Dive into an enriched learning landscape that resonates with the digital era, making classroom experiences more dynamic, relatable, and captivating."
      />
      {
        //<PricingComponent />
      }
      <ContactForm buttonText="Let us know you're interested" />
      <InfoScroller />
      <Download />
    </>
  );
}
