import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  background-color: #fff; // Assuming this is the background color from the image.
  color: white;
  justify-content: center;
  align-items: center;
`;

const TheTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #030852;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 0px 20px;
  }
`;
const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #030852;
  text-align: center;
  padding: 30px;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SectionTitle = ({
  title = "Enter title...",
  subtitle = "Enter subtitle...",
}) => {
  return (
    <div style={{}}>
      <Container>
        <div style={{ maxWidth: 1200 }}>
          <TheTitle>{title}</TheTitle>
          <Subtitle>{subtitle}</Subtitle>
        </div>
      </Container>
    </div>
  );
};

export default SectionTitle;
