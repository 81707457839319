// Import required libraries
import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled Components
const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 50px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: #fff;

  color: #ed9a3f;

  transition: all 0.3s;

  span {
    font-size: 3rem;
  }

  img {
    width: 60px;
    height: 60px;
  }

  font-size: 1.5rem;
  font-weight: 1.5rem;
`;

const LinksSection = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  gap: 10px;

  a {
    text-decoration: none;
    color: ${(props) => (props.contact ? "#ed9a3f" : "black")};
    font-size: 1rem;

    transition: all 0.3s;

    &: hover {
      color: #ed9a3f;
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    align-items: center;
  }

  ${(props) => (props.contact ? "align-items:center" : "")}
`;

const ContactUs = styled.div`
  a {
    font-size: 1.5rem;
    font-weight: 400;

    transition: all 0.3s;

    &: hover {
      color: #1e5a8a;
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const SocialIcon = styled.div`
  color: #fff;
  background-color: #386281;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  transistion: all 0.3s;

  &: hover {
    background-color: #ed9a3f;
  }
`;

const BottomText = styled.div`
  a {
    text-decoration: none;
    font-size: 1rem;
    color: black;
  }
`;

const Mission = styled.div`
  padding: 50px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  min-width: 20%;
  max-width: 40%;

  span {
    font-size: 1.5rem;
    font-weight: 500;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    max-width: 100%;
    text-align: center;
  }
`;

const BottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

// Footer Component
const Footer = () => {
  return (
    <FooterContainer>
      <TopSection>
        <LinksSection>
          <LinkGroup contact>
            <Logo>
              <img src="/images/logo.png" alt="Logo for get lit" />
              <span style={{ fontSize: "3rem" }}>GetLit</span>
            </Logo>
            <ContactUs>
              <Link
                to="/contact"
                style={{ fontSize: 20, paddingTop: 20, paddingBottom: 10 }}
              >
                Contact Us
              </Link>
            </ContactUs>
            <SocialIcons>
              <Link to="/contact">
                <SocialIcon>
                  <FaFacebookF size={25} />
                </SocialIcon>
              </Link>
              <Link to="/contact">
                <SocialIcon>
                  <FaInstagram size={25} />
                </SocialIcon>
              </Link>
              <Link to="/contact">
                <SocialIcon>
                  <FaLinkedinIn size={25} />
                </SocialIcon>
              </Link>
            </SocialIcons>
          </LinkGroup>
          {/* <LinkGroup>
              <span>Company</span>
              <Link to="/contact"> Team</Link>
              <Link to="/contact"> Partners</Link>
              <Link to="/contact"> News</Link>
            </LinkGroup>*/}
          <LinkGroup>
            <span>Features</span>
            {
            //<Link to="/our-model">Our Model</Link>
          }
            <Link to="/pricing">Pricing</Link>
            <Link to="/faqs">FAQS</Link>
          </LinkGroup>
        </LinksSection>
        <Mission>
          <span>Mission</span>
          At GetLit, we believe in the transformative power of literature to
          bridge cultures, spark conversations, and illuminate experiences.
          Driven by modern technology and culturally relevant media, our mission
          is to foster a deeper connection between literature and daily life,
          empowering today’s readers to engage, relate, and be enlightened.
        </Mission>
      </TopSection>
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#a8a8a8",
          marginBottom: 25,
        }}
      />
      <BottomSection>
        <BottomDiv>
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            © 2023 GOYE LLC
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BottomText>
              <Link to="https://www.termsfeed.com/live/5bc0e5b0-2efa-459d-97a8-0c32b6c117c2">
                Privacy Policy{" "}
              </Link>
            </BottomText>
            <BottomText>|</BottomText>
            <BottomText>
              <Link to="https://www.termsfeed.com/live/2e5e5d82-0164-4af9-8929-b1fa7152caa3">
                Terms of Service
              </Link>
            </BottomText>
          </div>
        </BottomDiv>
      </BottomSection>
    </FooterContainer>
  );
};

export default Footer;
