import SectionTitle from "../components/General/SectionTitle";
import Title from "../components/General/Title";
import ContactForm from "../components/Home/Contact";
import Download from "../components/Home/Download";
import Why from "../components/Home/Why";

export default function Contact() {
  return (
    <>
      <Title title="Contact" />
      <SectionTitle
        title="Connect with the GetLit Team"
        subtitle="Have questions, feedback, or simply want to chat? Drop us a message, and we'll get back to you in a literary jiffy!"
      />
      <ContactForm />
      <Why />
      <Download />
    </>
  );
}
