import React from "react";
import styled from "styled-components";
// Assuming you've installed react-icons, we'll use placeholders for now

const Container = styled.div`
  background-color: #6a49c8; // Assuming this is the background color from the image.
  color: white;
  text-align: center;
  padding: 300px 50px 100px 50px;
  @media (max-width: 768px) {
  }
`;

const TheTitle = styled.h2`
  font-size: 6rem;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #030852;

  @media (max-width: 996px) {
    font-size: 3rem;
  }
`;

const WaveSVG = styled.svg`
  width: 100%;
  margin-bottom: -15px;
  ${(props) =>
    props.reverse
      ? "transform: rotate(180deg); margin-top: -5%;"
      : "  margin-top: 100px;"}
`;

const Title = ({ title = "Enter title..." }) => {
  return (
    <div style={{ paddingBottom: 100 }}>
      <Container>
        <TheTitle>{title}</TheTitle>
      </Container>
      <WaveSVG reverse={true} viewBox="0 20 1440 150">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 130">
          <path
            fill="#6a49c8"
            d="m.5,86.92S156,.5,499,.5s760,79,971,79,450.5-52.67,450.5-52.67v152.7H.5v-92.61Z"
          />
        </svg>
      </WaveSVG>
    </div>
  );
};

export default Title;
