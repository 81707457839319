import React from "react";
import styled from "styled-components";
import YouTubeEmbed from "../widget/YoutubeEmbed";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0% 10%;
  padding-bottom: 20px;
  background-color: #ff;
  gap: 20px;
  margin-top: -50px;
  @media (max-width: 1400px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  color: #030852;
  margin-bottom: 20px;

  @media (max-width: 1400px) {
    text-align: center;
    font-size: 3rem;
    margin-top: 0px;
  }
`;

const Text = styled.p`
  font-size: 1.3rem;
  color: #030852;
  margin: 0px 0px 20px 0px;

  @media (max-width: 1400px) {
    text-align: center;
    font-size: 1rem;
    padding-bottom: 20px;
  }
`;

const RightContent = styled.div`
  flex: 1.5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1400px) {
    text-align: center;
    align-items: center;
  }
`;

const Button = styled.button`
  padding: 20px 20px;
  background-color: #ed9a3f;
  color: ed9a3f;
  border: none;
  width: 50%;
  border-radius: 15px 15px 15px 0px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;

  transition: all 0.3s;

  &:hover {
    background-color: #f5222d;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); // Slightly intensifying the shadow
  }
`;

const Subhero = ({ showButton = true, title, children }) => {
  return (
    <Container>
      <LeftContent>
        <YouTubeEmbed videoId={"dQw4w9WgXcQ&ab"} />
      </LeftContent>
      <RightContent>
        <Title>{title}</Title>
        <Text>{children}</Text>
        {showButton && (
          <Button style={{ minWidth: 250 }}>
            <a
              style={{ textDecorationLine: "none", color: "white" }}
              href="https://getlitnow.org/"
            >
              Try GetLit Now
            </a>
          </Button>
        )}
      </RightContent>
    </Container>
  );
};

export default Subhero;
